@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700');


/* DATE PICKER STYLE */
.react-datepicker__close-icon.datepicker-button-clear::after {
    background-color: transparent;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 22px;
    width: 22px;
    padding: 0;
    font-size: 16px;
}

.react-datepicker__close-icon.datepicker-button-clear:hover::after {
    background-color: var(--chakra-colors-gray-100);
}


.react-datepicker__view-calendar-icon .datepicker-input {
    padding-left: 30px !important;
}